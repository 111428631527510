<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <p>{{ content.cart.title }}</p>
          <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <div class="wines">
            <p class="empty" v-if="materiaisCarrinho.length == 0">
              {{ content.cart.empty }}
            </p>
            <ul>
              <li v-for="(wine, i) in materiaisCarrinho" :key="i">
                <a class="delete" @click="removeItem(i)"
                  ><span class="icon-icon-close"></span
                ></a>
                <div class="image">
                  <img :src="img_url + wine.img" />
                </div>
                <div class="wine-info">
                  <p class="name">{{ wine.name }}</p>
                  <p>{{ wine.year }} | {{ wine.type }}</p>
                  <div class="qt">
                    <p class="one-line">
                      {{ content.cart.qt }}*
                      <span>{{ wine.quantity }}</span>
                      <!-- <v-text-field
                        class="qt-input"
                        v-model.number="wine.quantity"
                        type="number"
                        single-line
                        disabled
                        @change="updateQt(i, wine.quantity)"
                      ></v-text-field> -->
                    </p>
                    <p class="one-line">
                      <a @click="decrease(i)" class="quantity">-</a>
                      <a @click="increase(i)" class="quantity">+</a>
                    </p>
                    <v-select
                      v-if="wine.box_quantity > 1"
                      class="unit-select"
                      :items="quantity_units"
                      item-text="text"
                      item-value="value"
                      :label="content.cart.quantity_label"
                      v-model="wine.quantitySelected"
                      @change="updateIt(i, wine.quantitySelected)"
                    ></v-select>

                    <p class="legenda" v-if="wine.quantitySelected == 'box'">
                      *{{ content.cart.subtitle }} {{ wine.box_quantity }} x
                      {{ wine.it }} lt
                    </p>
                    <p class="legenda" v-if="wine.quantitySelected == 'bottle'">
                      *{{ content.cart.subtitle2 }} - {{ wine.it }} lt
                    </p>
                    <p class="price" v-if="wine.discount > 0">
                      <span v-if="wine.quantitySelected == 'bottle'">{{
                        (
                          wine.price *
                          (1 - wine.discount) *
                          wine.quantity
                        ).toFixed(2)
                      }}</span>
                      <span v-if="wine.quantitySelected == 'box'">{{
                        (
                          wine.price *
                          wine.box_quantity *
                          (1 - wine.discount) *
                          wine.quantity
                        ).toFixed(2)
                      }}</span>
                      CHF
                    </p>

                    <p class="price" v-else>
                      <span v-if="wine.quantitySelected == 'bottle'">
                        {{ (wine.price * wine.quantity).toFixed(2) }}
                      </span>
                      <span v-if="wine.quantitySelected == 'box'">
                        {{
                          (
                            wine.price *
                            wine.box_quantity *
                            wine.quantity
                          ).toFixed(2)
                        }}
                      </span>
                      CHF
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <div class="counter">
            <img :src="img_url + 'icon-counter.png'" />
            <div>
              <p>{{ qt }}</p>
            </div>
          </div>
          <div class="footer-container">
            <p class="msg">{{ msg }}</p>
            <v-text-field
              style="margin-bottom: 10px; margin-top: 10px"
              outlined
              dark
              dense
              hide-details
              v-on:keyup="checkPromocode()"
              :label="content.cart.to_discount_promocode"
              v-model="userPromocode"
            ></v-text-field>
            <div class="subtotal">
              <p>
                <span class="discount">{{ content.cart.original }}</span
                ><br />

                <!--- quantity discount -->
                <!-- <span class="discount"
                  >{{ content.cart.discount }} (
                  {{ discounts.quantity.discount * 100 }}%)</span
                > -->
                <!-- {{ content.cart.total }} -->

                <!-- end quantity discount --->

                <!--- promocode discount -->
                <span class="discount">{{ content.cart.discount }}<br /></span>
                {{ content.cart.total }}
                <!--- end promocode discount -->

                <span class="total-legenda">{{ content.cart.total2 }}</span>
              </p>
              <p>
                <span v-if="total != totalWDiscount" class="underline"
                  >{{ totalWDiscount.toFixed(2) }} CHF<br
                /></span>
                <span v-else class="discount"
                  >{{ totalWDiscount.toFixed(2) }} CHF<br
                /></span>
                <span class="discount"
                  >{{ (totalWDiscount - total).toFixed(2) }} CHF</span
                ><br />

                <span>{{ total.toFixed(2) }} CHF</span>
              </p>
            </div>
            <!-- <div v-if="total != totalWDiscount" class="discount">
              <p>
                {{ content.cart.discount }} (
                {{ discounts.quantity.discount * 100 }}%)
              </p>
              <p>
                <span>{{ (totalWDiscount - total).toFixed(2) }} CHF</span>
              </p>
            </div> -->

            <!--- quantity discount -->
            <!-- <p
              class="discount-ps"
              v-if="
                discounts.quantity.active == 1 &&
                qt < discounts.quantity.quantity
              "
            >
              {{ content.cart.to_discount }}
              {{ discounts.quantity.quantity - qt }}
              {{ content.cart.to_discount1 }}
              {{ discounts.quantity.discount * 100 }}%
              {{ content.cart.to_discount2 }}
            </p> -->
            <!--- end quantity discount -->

            <!--- promocode discount -->
            <!-- <p class="discount-ps" v-if="promocodes.length > 0">
              {{ content.cart.to_discount_promocode }}
            </p> -->
            <!--- end promocode discount -->

            <a
              v-on:click="shipping()"
              class="white-btn checkout"
              v-if="login"
              >{{ content.cart.btn }}</a
            >
            <a
              v-on:click="changeModal('login')"
              class="white-btn checkout"
              v-else
              >{{ content.login.title }}</a
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from "@/modules/api_calls";

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["backdoor"],
  data() {
    return {
      dialog: true,
      n: 0,
      quantity: {},
      delivery: {},
      promocodes: [
        { code: "teste", discount: 0.15 },
        { code: "teste1", discount: 0.5 },
      ],
      promocode: { code: "", discount: 0 },

      items: [],
      msg: "",
      quantitySelected: "box",
      userPromocode: "",
    };
  },
  async created() {
    // let app = this
    let quantityEP = "quantity/campaigns/bo-list-all";
    let deliveryEP = "delivery/campaigns/bo-list-all";
    let promocodesEP = "promocode/campaigns/bo-list-all";

    const qt_res = await api_calls.call_api_get(quantityEP);
    this.quantity = qt_res.data[0];

    const delivery_res = await api_calls.call_api_get(deliveryEP);
    this.delivery = delivery_res.data[0];

    const promocode_res = await api_calls.call_api_get(promocodesEP);
    this.promocodes = promocode_res.data;
    window.sessionStorage.setItem("promocode", JSON.stringify(this.promocode));
  },
  methods: {
    checkPromocode: async function () {
      for (let item of this.promocodes) {
        if (item.code == this.userPromocode) {
          this.promocode = item;
        }
      }

      let app = this;
      window.sessionStorage.setItem("promocode", JSON.stringify(app.promocode));
    },
    increase: async function (i) {
      this.n += 1;
      let quantidade = 0;

      if (this.materiaisCarrinho[i].quantitySelected == "box") {
        quantidade =
          (this.materiaisCarrinho[i].quantity + 1) *
          this.materiaisCarrinho[i].box_quantity;
      } else {
        quantidade = this.materiaisCarrinho[i].quantity + 1;
      }

      if (parseInt(this.materiaisCarrinho[i].stock) >= parseInt(quantidade)) {
        this.materiaisCarrinho[i].quantity++;
        let cart = this.materiaisCarrinho;

        window.localStorage.setItem("cart", JSON.stringify(cart));

        this.$emit("update-cart");
      }
    },
    decrease: async function (i) {
      this.n += 1;
      if (parseInt(this.materiaisCarrinho[i].quantity) > 1) {
        this.materiaisCarrinho[i].quantity =
          parseInt(this.materiaisCarrinho[i].quantity) - 1;
      } else {
        this.materiaisCarrinho[i].quantity = 1;
      }
      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    async updateQt(i, qt) {
      this.n += 1;
      let quantidade = 0;

      if (this.materiaisCarrinho[i].quantitySelected == "bottle") {
        quantidade =
          this.materiaisCarrinho[i].quantity *
          this.materiaisCarrinho[i].box_quantity;
      } else {
        quantidade = parseInt(qt);
      }

      if (parseInt(this.materiaisCarrinho[i].stock) >= parseInt(quantidade)) {
        this.materiaisCarrinho[i].quantity = parseInt(qt);
        let cart = this.materiaisCarrinho;

        window.localStorage.setItem("cart", JSON.stringify(cart));

        this.$emit("update-cart");
      } else {
        if (this.materiaisCarrinho[i].quantitySelected == "box") {
          this.materiaisCarrinho[i].quantity = Math.floor(
            parseInt(this.materiaisCarrinho[i].stock) /
              parseInt(this.materiaisCarrinho[i].box_quantity)
          );
        } else {
          this.materiaisCarrinho[i].quantity = parseInt(
            this.materiaisCarrinho[i].stock
          );
        }
        // this.materiaisCarrinho[i].quantity = parseInt(qt);
        let cart = this.materiaisCarrinho;

        window.localStorage.setItem("cart", JSON.stringify(cart));

        this.n += 1;

        this.$emit("update-cart");
      }
    },

    // async updateIt(i, it) {
    //   this.n += 1;

    //   this.materiaisCarrinho[i].quantitySelected = it;

    //   if (this.materiaisCarrinho[i].quantitySelected == "bottle") {
    //     this.materiaisCarrinho[i].quantity = Math.floor(
    //       parseInt(this.materiaisCarrinho[i].stock) /
    //         parseInt(this.materiaisCarrinho[i].box_quantity)
    //     );
    //   } else {
    //     this.materiaisCarrinho[i].quantity = parseInt(
    //       this.materiaisCarrinho[i].stock
    //     );
    //   }

    //   let cart = this.materiaisCarrinho;

    //   window.localStorage.setItem("cart", JSON.stringify(cart));

    //   this.$emit("update-cart");
    // },
    async updateIt(i, it) {
      this.n += 1;
      this.materiaisCarrinho[i].quantitySelected = it;

      if (it == "box") {
        if (
          Math.floor(
            this.materiaisCarrinho[i].quantity *
              parseInt(this.materiaisCarrinho[i].box_quantity)
          ) >= this.materiaisCarrinho[i].stock
        ) {
          this.materiaisCarrinho[i].quantity = Math.floor(
            parseInt(this.materiaisCarrinho[i].stock) /
              parseInt(this.materiaisCarrinho[i].box_quantity)
          );
        }
      } else {
        this.materiaisCarrinho[i].quantity = Math.floor(
          parseInt(this.materiaisCarrinho[i].quantity) *
            parseInt(this.materiaisCarrinho[i].box_quantity)
        );
      }

      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },

    async removeItem(i) {
      this.n += 1;
      this.materiaisCarrinho.splice(i, 1);

      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    shipping() {
      let app = this;

      if (this.materiaisCarrinho.length == 0) {
        this.msg = this.content.forms.cart_empty;
      } else {
        app.$emit("update-modal", "cart2");
      }
    },
  },
  computed: {
    login() {
      this.n;
      let res =
        window.localStorage.getItem("loggedIn") == "true" ? true : false;

      return res;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
    totalWDiscount() {
      this.n;
      var sum = 0;
      this.materiaisCarrinho.forEach((e) => {
          if (e.quantitySelected == "box") {
            sum += e.price * e.quantity * e.box_quantity;
            console.log(sum)
          } else {
            sum += e.price * e.quantity;
          }
      });
      return sum;
    },

    total() {
      this.n;
      var sum = 0;
      let qt = 0;
      console.log(this.materiaisCarrinho);
      this.materiaisCarrinho.forEach((e) => {
        if (e.discount > 0) {
          if (e.quantitySelected == "box") {
            sum += e.price * (1 - e.discount) * e.quantity * e.box_quantity;
          } else {
            sum += e.price * (1 - e.discount) * e.quantity;
          }
          if (e.quantitySelected == "box") {
            qt += e.quantity * e.box_quantity;
          } else {
            qt += e.quantity;
          }
        } else {
          if (e.quantitySelected == "box") {
            sum += e.price * e.quantity * e.box_quantity;
          } else {
            sum += e.price * e.quantity;
          }
          if (e.quantitySelected == "box") {
            qt += e.quantity * e.box_quantity;
            console.log(qt);
          } else {
            qt += e.quantity;
          }
        }
      });

      // if (
      //   qt >= this.discounts.quantity.quantity &&
      //   this.discounts.quantity.active == 1
      // )
      if (this.promocode.discount != 0) {
        return sum * (1 - this.discounts.promocode.discount);
      } else {
        return sum;
      }
    },
    qt() {
      var qt = 0;
      this.materiaisCarrinho.forEach((e) => {
        if (e.quantitySelected == "box") {
          qt += e.quantity * e.box_quantity;
        } else {
          qt += e.quantity;
        }
      });
      return qt;
    },
    m() {
      this.backdoor;
      return this.n;
    },
    discounts() {
      let temp = {
        quantity: this.quantity,
        delivery: this.delivery,
        promocode: this.promocode,
      };

      return temp;
    },

    quantity_units() {
      let quantity_units = [
        { text: this.content.cart.quantity_unit1, value: "bottle" },
        { text: this.content.cart.quantity_unit2, value: "box" },
      ];
      return quantity_units;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.counter {
  position: absolute;
  top: -75px;
  right: 20px;
  width: 30px;
  text-align: center;
  img {
    width: 100%;
  }
  div {
    position: absolute;
    top: 63px;
    right: 4.5px;
    text-align: center !important;
    width: 22px;
    height: 30px;
  }
  p {
    text-align: center !important;
    position: relative;
    margin: 0 auto;
    width: 22px;
    font-weight: 600;
    color: $red;
    font-size: 13px;
  }
}
</style>