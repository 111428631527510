<template>
  <div class="dialog">
    <v-dialog v-model="dialog" overlay-opacity="0.8" persistent>
      <div id="form-container" class="form-container">
        <div class="header">
          <a class="arrow" v-on:click="changeModal('cart')"
            ><span class="icon-icon-arrow-right"></span
          ></a>
          <p>{{ content.cart.title2 }}</p>
           <a v-on:click="changeModal('')"
            ><span class="icon-icon-close"></span
          ></a>
        </div>
        <div class="content-dialog">
          <p class="address_title">1. {{ content.cart.step2 }}</p>
          <span class="dash"></span>
          <template>
              <v-radio-group v-model="selectedMethod">
                <v-radio
                  v-for="i in shippingMethods"
                  :key="i.value"
                  :label="i.label"
                  :value="i.value"
                ></v-radio>
              </v-radio-group>
          </template>
          <div
            v-if="selectedMethod == 'home_delivery'"
            class="shipping_address"
          >
            <p class="address_title">2. {{ content.cart.step3 }}</p>
            <span class="dash"></span>
            <v-select
              :label="content.cart.label1"
              :items="userAddresses"
              item-text="name"
              return-object
              v-model="selectedDeliveryAddress"
            ></v-select>
          </div>
          <p class="shipping_addressII" v-if="selectedMethod == 'pick_collect'" >{{content.cart.pickup_address}}:<br>RUE DE LA CITÉ 1 - Multiburo<br>1204 GENEVA – SWITZERLAND</p>
          <p class="address_title" v-if="selectedMethod == 'home_delivery'">
            3. {{ content.cart.step4 }}
          </p>
          <p class="address_title" v-else>2. {{ content.cart.step4 }}</p>
          <span class="dash"></span>
          <div class="shipping_address">
            <v-text-field v-model="name" :label="content.cart.label_name"></v-text-field>
            <v-text-field v-model="delivery_notes" :label="content.address.label_extra_info"></v-text-field>
            <v-select
              :label="content.cart.label2"
              :items="userAddresses"
              item-text="name"
              return-object
              v-model="selectedBillingAddress"
              color: grey
            ></v-select>
            <a class="line-btn" v-on:click="changeModal('new-address')"
              >{{content.cart.new_address}}</a
            >
          </div>
        </div>
        <div class="footer">
          <div class="footer-container">
            <p class="msg">{{ msg }}</p>
            <div class="subtotal shipping">
              <p>{{ content.cart.subtotal }}</p>
              <p>
                <span>{{ total.toFixed(2) }} CHF</span>
              </p>
            </div>
            <div class="subtotal shipping">
              <p>{{ content.cart.delivery }}</p>
              <p>
                <span>{{ delivery_cost.toFixed(2) }} CHF</span>
              </p>
            </div>
            
            <div>
              <p>{{ content.cart.total }} <span class="total-legenda">{{ content.cart.total2 }}</span></p>
              <p>
                <span>{{ (total + delivery_cost).toFixed(2) }} CHF</span>
              </p>
            </div>
            <p class="discount-ps"
              v-if="discounts.delivery.active == 1 && qt < discounts.delivery.quantity && delivery_cost > 0"
            >
              {{ content.cart.to_discount }} {{ discounts.delivery.quantity - qt }} {{ content.cart.to_discount3 }} 
            </p>
            <a v-on:click="payment()" class="white-btn checkout">{{
              content.cart.btn2
            }}</a>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from "@/modules/api_calls";

export default {
  mixins: [computedMixins, methodsMixins],
  props: ["backdoor"],
  data() {
    return {
      dialog: true,
      n: 0,
      name: "",
      msg:"",
      delivery_notes: "",
      // delivery_cost: 10,
      selectedMethod: "",
      selectedDeliveryAddress: {},
      selectedBillingAddress: {},
      quantity: {},
      delivery: {},
      userAddressesBase: [],
      pickup: {},
      promocodes: [
        { code: "teste", discount: 0.15 },
        { code: "teste1", discount: 0.5 },
      ],
      promocode: { code: "", discount: 0 },
      userPromocode: ""
    };
  },
  async created() {
    // let app = this
    let quantityEP = "quantity/campaigns/bo-list-all";
    let deliveryEP = "delivery/campaigns/bo-list-all";
    let pickupEP = "pickup/list-all";
    let addressesEP = "addresses/user-list/" + window.localStorage.getItem("user_id");
    let promocodesEP = "promocode/campaigns/bo-list-all";

    this.promocode = JSON.parse(window.sessionStorage.getItem('promocode'))

    this.name = window.localStorage.getItem("name");

    const qt_res = await api_calls.call_api_get(quantityEP);
    this.quantity = qt_res.data[0];

    const delivery_res = await api_calls.call_api_get(deliveryEP);
    this.delivery = delivery_res.data[0];

    const pickup_res = await api_calls.call_api_get(pickupEP);
    this.pickup = pickup_res.data[0];

    const addresses_res = await api_calls.call_api_get(addressesEP);
    this.userAddressesBase = addresses_res.data;

    let mainAddress = this.userAddressesBase.filter(item => item.main == 1) || null
    mainAddress = mainAddress[0]

    const promocode_res = await api_calls.call_api_get(promocodesEP);
    this.promocodes = promocode_res.data;

    if(mainAddress != null) {
      this.selectedBillingAddress = mainAddress
      this.selectedDeliveryAddress = mainAddress
    }

    let checkoutInfo = JSON.parse(window.localStorage.getItem('checkoutInfo'))

    if(typeof checkoutInfo != undefined && checkoutInfo != null) {
      this.name = checkoutInfo.billing_name || ""
      this.selectedDeliveryAddress = checkoutInfo.delivery_address
      this.selectedBillingAddress = checkoutInfo.billing_address
      this.selectedMethod = checkoutInfo.delivery_method
    }

    this.n += 1;
  },
  methods: {
    increase: async function (i) {
      this.n += 1;
      this.materiaisCarrinho[i].quantity++;
      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    decrease: async function (i) {
      this.n += 1;
      if (parseInt(this.materiaisCarrinho[i].quantity) > 1) {
        this.materiaisCarrinho[i].quantity =
          parseInt(this.materiaisCarrinho[i].quantity) - 1;
      } else {
        this.materiaisCarrinho[i].quantity = 1;
      }
      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    async removeItem(i) {
      this.n += 1;
      this.materiaisCarrinho.splice(i, 1);

      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");
    },
    payment() {
      let app = this;
      if (this.selectedMethod == "") {
        this.msg = this.content.forms.shipping_selectAddress;
      } 
      else if (this.selectedMethod == "home_delivery" && (this.selectedDeliveryAddress.id == undefined || this.selectedDeliveryAddress.id == 'undefined')) {
        this.msg = this.content.forms.shipping_address;
      } 
      else {
        let checkoutInfo = {
          order_items: this.materiaisCarrinho,
          items_cost: this.total,
          delivery_method: this.selectedMethod,
          delivery_cost: this.delivery_cost,
          delivery_address: this.selectedDeliveryAddress,
          delivery_notes: this.delivery_notes,
          billing_name: this.name,
          billing_address: this.selectedBillingAddress
        }
        window.localStorage.setItem('checkoutInfo', JSON.stringify(checkoutInfo))

        
        let lastOrder = JSON.parse(window.localStorage.getItem('checkoutInfo'))

        for(let i in lastOrder.order_items) {
          if(parseFloat(lastOrder.order_items[i].discount) == 0) {
            lastOrder.order_items[i].final_discount = parseFloat(this.promocode.discount).toFixed(2)
          }
          else {
            lastOrder.order_items[i].final_discount = parseFloat(lastOrder.order_items[i].discount * (1 - this.promocode.discount)).toFixed(2);
          }
        }

        window.localStorage.setItem('checkoutInfo', JSON.stringify(lastOrder))

        app.$emit("update-modal", "cart3");
      }
    },
  },
  computed: {
    shippingMethods() {
      this.n
      let shippingMethods = []
      if(this.pickup.allow_pickup == '0') {
        shippingMethods = [
          { label: this.content.cart.shipping1, value: "home_delivery" }
        ];
      }
      else {
        shippingMethods = [
          { label: this.content.cart.shipping1, value: "home_delivery" },
          {
            label: this.content.cart.shipping2,
            value: "pick_collect",
          },
        ];
      }
      return shippingMethods;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
    totalWDiscount() {
      this.n;
      var sum = 0;
      this.materiaisCarrinho.forEach((e) => {
          if (e.quantitySelected == "box") {
            sum += e.price * e.quantity * e.box_quantity;
            console.log(sum)
          } else {
            sum += e.price * e.quantity;
          }
      });
      return sum;
    },

    total() {
      this.n;
      var sum = 0;
      let qt = 0;
      console.log(this.materiaisCarrinho);
      this.materiaisCarrinho.forEach((e) => {
        if (e.discount > 0) {
          if (e.quantitySelected == "box") {
            sum += e.price * (1 - e.discount) * e.quantity * e.box_quantity;
          } else {
            sum += e.price * (1 - e.discount) * e.quantity;
          }
          if (e.quantitySelected == "box") {
            qt += e.quantity * e.box_quantity;
          } else {
            qt += e.quantity;
          }
        } else {
          if (e.quantitySelected == "box") {
            sum += e.price * e.quantity * e.box_quantity;
          } else {
            sum += e.price * e.quantity;
          }
          if (e.quantitySelected == "box") {
            qt += e.quantity * e.box_quantity;
            console.log(qt);
          } else {
            qt += e.quantity;
          }
        }
      });

      // if (
      //   qt >= this.discounts.quantity.quantity &&
      //   this.discounts.quantity.active == 1
      // )
      if (this.promocode.discount != 0) {
        return sum * (1 - this.discounts.promocode.discount);
      } else {
        return sum;
      }
    },
    qt() {
      var qt = 0;
      this.materiaisCarrinho.forEach((e) => {
        qt += e.quantity;
      });
      return qt;
    },
    m() {
      this.backdoor;
      return this.n;
    },
    discounts() {
      let temp = {
        quantity: this.quantity,
        delivery: this.delivery,
        promocode: this.promocode,
      };

      return temp;
    },
    delivery_cost() {
      let temp = parseFloat(this.selectedDeliveryAddress.delivery_cost) || 0;
      var qt = 0;

      this.materiaisCarrinho.forEach((e) => {
        if (e.discount > 0) {
          qt += e.quantity;
        } else {
          qt += e.quantity;
        }
      });

      if (
        (
          parseFloat(qt) >= parseFloat(this.discounts.delivery.quantity) &&
          this.discounts.delivery.active == 1 &&
          this.discounts.delivery.offer_delivery == 1
        ) ||
        (
          parseFloat(this.selectedDeliveryAddress.delivery_min_value) > 0 &&
          parseFloat(this.total) > parseFloat(this.selectedDeliveryAddress.delivery_min_value)
        ) ||
        this.selectedMethod == "pick_collect"
      ) {
        temp = 0;
      }

      return temp;
    },
    userAddresses() {
      this.n;
      return this.userAddressesBase;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>