<template>
  <section id="top" class="homepage">
    <v-carousel hide-delimiters :show-arrows="slides.length > 1">
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        :src="img_url + slide.image"
      >
        <div class="content">
          <h4 v-html="slide.content.title"></h4>
          <p v-html="slide.content.description"></p>
          <a
            v-if="slide.content.button_link.slice(0, 4) == 'http'"
            :href="slide.content.button_link"
            class="button-fill"
          >
            {{ slide.content.button_text }}
          </a>
          <router-link
            v-else
            :to="slide.content.button_link"
            class="button-fill"
          >
            {{ slide.content.button_text }}
          </router-link>
        </div>
      </v-carousel-item>
    </v-carousel>
    <OurSelection @update-cart="updateCart()" />
    <div id="about" class="about">
      <div class="aside">
        <h2>{{ content.about.title }}</h2>
        <p v-html="about.description"></p>
      </div>
      <div class="scheme">
        <ul>
          <li v-for="(region, j) in filteredRegions" :key="j">
            <a :href="`/online_store/-/${region.region}`"></a>
            <div
              :style="{
                backgroundImage: 'url(' + img_url + encodeURIComponent(region.image).replace('%2F', '/') + ')',
              }"
            ></div>
            <p class="title">{{ region.region }}</p>
            <p class="number">{{ region.num_wines }} {{ content.about.wine }}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import OurSelection from "@/components/Our-selection";
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from '@/modules/api_calls'

export default {
  name: "Home",
  mixins: [computedMixins, methodsMixins],
  components: {
    OurSelection,
  },
  data() {
    return {
      // slides: [
      //   {
      //     title: "Humilitas Touriga Nacional",
      //     description:
      //       "After the success of Humanitas, we are pleased to present the latest launch of Vinha das Virtudes, the Humilitas Réserve 2015. A limited production of only 1130 bottles for the most demanding wine lovers. ",
      //     button_text: "Know more",
      //     button_link: "/product/1",
      //     image: "bg_home.jpg",
      //   },
      // ],
      slideItems: [],
      regions: [
        // {
        //   name: "Alentejo",
        //   total: "13",
        //   image: "bg_about_alentejo.jpg",
        // },
        // {
        //   name: "Douro",
        //   total: "11",
        //   image: "bg_about_douro.jpg",
        // },
        // {
        //   name: "Minho",
        //   total: "10",
        //   image: "bg_about_minho.jpg",
        // },
        // {
        //   name: "Dão",
        //   total: "5",
        //   image: "bg_about_dao.jpg",
        // },
        // {
        //   name: "Beira",
        //   total: "3",
        //   image: "bg_about_beira.jpg",
        // },
      ],
      aboutData: {},

      // slidesEP: "home/bo-banner-items",
      // aboutEP: "home/bo-banner-items",
    };
  },
  computed: {
    slidesEP() {
      let temp = "home/"+this.version+"banner-items"
      return temp
    },
    aboutEP() {
      let temp = "home/"+this.version+"banner-items"
      return temp
    },
    about() {
      // this.n;
      let temp = {};

      temp = this.aboutData;
      if (typeof temp.content != "undefined" && typeof temp.content == "string") {
        let content = JSON.parse(temp.content);
        let lang = this.pageLanguage.toLowerCase()
                    content = content[lang]

        for (let k in content) {
          temp[k] = content[k];
        }

        temp.content = content;
      }

      return temp;
    },
    filteredRegions() {
      return this.regions.slice(0, 6);
    },
    img_url() {
      return this.$store.state.img_domain;
    },
    slides() {
      let final = [];
      let temp = {};
      this.n;

      for (let item of this.slideItems) {
        temp = item;
        // CONTENT DECODE
        if (
          typeof item.content != "undefined" &&
          typeof item.content != "object"
        ) {
          let content = JSON.parse(item.content);
          let lang = this.pageLanguage.toLowerCase()
          temp.content = content[lang]
        }

        final.push(temp);
      }

      return final;
    },
  },
  methods: {
    filteredProducts() {
      
    },
    async updateCart() {
      this.$emit('update-cart')
    }
  },
  async created() {
    await this.initializeSlides(this.slidesEP);

    let data = await api_calls.call_api_get('about/items/bo-list-all')

    this.regions = data.data

    let about_data = await api_calls.call_api_get('about/bo-list-all')

    this.aboutData = about_data.data[0]

    this.faqs = "faqs" || "";
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.content {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  h4 {
  }
  p {
    color: white !important;
    p {
      color: white !important;
    }
    span {
      color: white !important;
    }
  }
  a {
    margin: 0 auto;
    margin-top: 30px;
  }
}

.about {
  display: flex;
  min-height: 900px;
  @include responsive(0, 512px) {
    display: block;
  }
  .aside {
    width: 350px;
    background-color: #575756;
    padding: 100px 40px;
    @include responsive(0, 512px) {
      width: 100%;
      padding: 40px 20px;
      margin-bottom: 15px;
    }
    h2 {
      color: white;
      text-align: left;
      margin-bottom: 30px;
    }
    p {
      color: white;
      text-align: left;
    }
  }
  .scheme {
    width: calc(100% - 350px);
    background-color: white;
    @include responsive(0, 512px) {
      width: 100%;
    }
    ul {
      height: 100%;
      position: relative;
      li {
        display: inline-block;
        position: relative;
        vertical-align: top;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.3;
          z-index: 1;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 4;
          top: 0;
        }
        div {
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        .title {
          color: white;
          text-align: left;
          margin-bottom: 0px;
          position: relative;
          z-index: 2;
          margin-top: 30px;
          margin-left: 30px;
        }
        .number {
          color: white;
          text-align: left;
          font-weight: 300;
          position: relative;
          z-index: 2;
          margin-left: 30px;
        }
      }
      li:nth-child(1) {
        width: calc(66.6% - 7.5px);
        height: calc(33.3% - 15px);
        margin-right: 7.5px;
        margin-bottom: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          margin-bottom: 7.5px;
        }
      }
      li:nth-child(2) {
        width: calc(33.3% - 7.5px);
        height: calc(66.6% - 7.5px);
        margin-left: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          margin-bottom: 7.5px;
          margin-top: 7.5px;
        }
      }
      li:nth-child(3) {
        width: calc(33.3% - 7.5px);
        height: calc(33.3% - 7.5px);
        transform: translateY(-100%);
        margin-right: 7.5px;
        margin-bottom: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          transform: translateY(0%);
          margin-bottom: 7.5px;
          margin-top: 7.5px;
        }
      }
      li:nth-child(4) {
        width: calc(33.3% - 15px);
        height: calc(33.3% - 7.5px);
        transform: translateY(-100%);
        margin-right: 7.5px;
        margin-left: 7.5px;
        margin-bottom: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          transform: translateY(0%);
          margin-top: 7.5px;
          margin-bottom: 7.5px;
        }
      }
      li:nth-child(5) {
        width: calc(50% - 7.5px);
        height: calc(33.3% - 7.5px);
        transform: translateY(-100%);
        margin-right: 7.5px;
        margin-top: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          transform: translateY(0%);
          margin-top: 7.5px;
          margin-bottom: 7.5px;
        }
      }
      li:nth-child(6) {
        width: calc(50% - 7.5px);
        height: calc(33.3% - 7.5px);
        transform: translateY(-100%);
        margin-left: 7.5px;
        margin-top: 7.5px;
        @include responsive(0, 1024px) {
          width: 100%;
          height: 250px;
          margin: 0;
          transform: translateY(0%);
          margin-top: 7.5px;
        }
      }
    }
  }
}

.v-carousel {
  height: 700px !important;
  @include responsive(0, 512px) {
    height: 500px !important;
  }
}

.v-image {
  height: 100% !important;
}
</style>