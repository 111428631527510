<template>
  <v-app>
    <Registo
      v-if="modal == 'registo'"
      @update-modal="updateModal"
      @open-footer="updateFooter"
    />
    <Login v-if="modal == 'login'" @update-modal="updateModal" />
    <ForgotPassword v-if="modal == 'forgot-password'" @update-modal="updateModal" />
    <Details
      v-if="modal == 'details'"
      @update-modal="updateModal"
      @open-footer="updateFooter"
    />
    <NewAddress
      v-if="modal == 'new-address'"
      @update-modal="updateModal"
      @open-footer="updateFooter"
    />
    <Addresses v-if="modal == 'addresses'" @update-modal="updateModal" />
    <Search v-if="modal == 'search'" @update-modal="updateModal" />
    <User v-if="modal == 'user'" @update-modal="updateModal" />
    <Cart
      v-if="modal == 'cart'"
      @update-modal="updateModal"
      @update-cart="updateCart"
    />
    <Cart2
      v-if="modal == 'cart2'"
      @update-modal="updateModal"
      @update-cart="updateCart"
    />
    <Cart3
      v-if="modal == 'cart3'"
      @update-modal="updateModal"
      @update-cart="updateCart"
    />
    <Cart4
      v-if="modal == 'cart4'"
      @update-modal="updateModal"
      @update-cart="updateCart"
    />
    <CartCreditCard
      v-if="modal == 'cart-credit-card'"
      @update-modal="updateModal"
      @update-cart="updateCart"
    />
    <Privacy
      v-if="footer == 'privacy'"
      @update-modal="updateFooter"
      :pp="privacyP"
    />
    <Faqs v-if="footer == 'faqs'" @update-modal="updateFooter" />
    <Newsletter v-if="newsletterInfo.status_publish == 1" :nlinfo="newsletterInfo" @update-modal="updateModal" @open-footer="updateFooter" />

    <div class="menu-desktop">
      <div class="main-menu">
        <ul>
          <li>
            <router-link to="/" v-scroll-to="{ el: '#about', offset: -125 }">{{
              content.header.main_menu.menu2
            }}</router-link>
          </li>
          <li>
            <router-link
              to="/news-and-events"
              v-scroll-to="{ el: '#top', offset: -200 }"
              >{{ content.header.main_menu.menu3 }}</router-link
            >
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/" v-scroll-to="{ el: '#top', offset: -200 }"
              ><img :src="img_url + 'logo.png'"
            /></router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link
              :to="{ path: '/', hash: 'ourselection' }"
              v-scroll-to="{ el: '#ourselection', offset: -125 }"
              >{{ content.header.main_menu.menu1 }}</router-link
            >
          </li>
          <li>
            <router-link
              to="/online_store"
              v-scroll-to="{ el: '#top', offset: -200 }"
              >{{ content.header.main_menu.menu4 }}</router-link
            >
          </li>
        </ul>
        <ul>
          <li v-if="login == false" class="login-word">
            <a v-on:click="modal = 'login'">{{
              content.header.main_menu.menu5
            }}</a>
          </li>
          <li v-else>
            <!-- <a @click="openUser()" class="user-btn"
              ><span class="icon-icon-user user-btn"></span
            ></a> -->
            <a @click="modal = 'user'" class="user-btn"
              ><span class="icon-icon-user user-btn"></span
            ></a>
          </li>
          <li>
            <a v-on:click="modal = 'search'"
              ><span class="icon-icon-search"></span
            ></a>
          </li>
          <li>
            <a v-on:click="modal = 'cart'"
              ><v-badge
                :content="cartItems"
                :value="cartItems"
                color="primary"
                overlap
              >
                <!-- <v-icon color="primary" medium>mdi-cart</v-icon> -->
                <span class="icon-icon-cart"></span> </v-badge
            ></a>
          </li>

          <li>
            <v-select :items="langs" dense v-model="pageLanguage"> </v-select>
            <span class="icomoon icon-icon-arrow-right"></span>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div id="secondary-menu" class="secondary-menu">
      <p>{{ user.name }}</p>
      <a id="user-btn" class="user-icon"
        ><span class="icon-icon-user"></span
      ></a>
      <ul>
        <li>
          <a v-on:click="modal = 'details'">{{
            content.header.secondary_menu.menu1
          }}</a>
        </li>
        <li>
          <router-link to="/orders">{{
            content.header.secondary_menu.menu2
          }}</router-link>
        </li>
        <li>
          <a v-on:click="modal = 'addresses'">{{
            content.header.secondary_menu.menu3
          }}</a>
        </li>
        <li>
          <a v-on:click="login = false">{{
            content.header.secondary_menu.menu4
          }}</a>
        </li>
      </ul>
    </div> -->

    <div class="menu-mobile">
      <v-navigation-drawer v-model="drawer" app color="primary" dark>
        <!-- <ul class="menu-mobileI">
          <li>
            <router-link
              :to="{ path: '/', hash: 'ourselection' }"
              v-scroll-to="{ el: '#ourselection', offset: -125 }"
              >{{ content.header.main_menu.menu1 }}</router-link
            >
          </li>
          <li>
            <router-link to="/" v-scroll-to="{ el: '#about', offset: -125 }">{{
              content.header.main_menu.menu2
            }}</router-link>
          </li>
          <li>
            <router-link to="/news-and-events">{{
              content.header.main_menu.menu3
            }}</router-link>
          </li>
          <li>
            <router-link to="/online_store">{{
              content.header.main_menu.menu4
            }}</router-link>
          </li>
          <li><a v-html="lang"></a></li>
        </ul> -->

        <v-list>
          <v-list-item-content>
            <v-list-item>
              <router-link
                to="/"
                v-scroll-to="{ el: '#about', offset: -125 }"
                >{{ content.header.main_menu.menu2 }}</router-link
              >
            </v-list-item>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item>
              <router-link to="/news-and-events">{{
                content.header.main_menu.menu3
              }}</router-link>
            </v-list-item>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item>
              <router-link
                :to="{ path: '/', hash: 'ourselection' }"
                v-scroll-to="{ el: '#ourselection', offset: -125 }"
                >{{ content.header.main_menu.menu1 }}</router-link
              >
            </v-list-item>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item>
              <router-link to="/online_store">{{
                content.header.main_menu.menu4
              }}</router-link>
            </v-list-item>
          </v-list-item-content>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content class="menu-white">
                <v-list-item>{{ content.header.main_menu.menu6 }}</v-list-item>
              </v-list-item-content>
            </template>
            <v-list-item class="sub-item">
              <v-list-item-content>
                <v-list-item>
                  <a
                    :class="pageLanguage == 'EN' ? 'active' : ''"
                    @click="pageLanguage = 'EN'"
                    >EN</a
                  >
                </v-list-item>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="sub-item">
              <v-list-item-content>
                <v-list-item>
                  <a
                    :class="pageLanguage == 'FR' ? 'active' : ''"
                    @click="pageLanguage = 'FR'"
                    >FR</a
                  >
                </v-list-item>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="sub-item">
              <v-list-item-content>
                <v-list-item>
                  <a
                    :class="pageLanguage == 'PT' ? 'active' : ''"
                    @click="pageLanguage = 'PT'"
                    >PT</a
                  >
                </v-list-item>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="white" height="100">
        <v-app-bar-nav-icon
          color="primary"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title
          ><router-link
            to="/"
            v-scroll-to="{ el: '#top', offset: -200 }"
            class="logo"
            ><img :src="img_url + 'logo.png'"
          /></router-link>
          <ul class="menu-mobileII">
            <li v-if="login == false">
              <a v-on:click="modal = 'login'">{{
                content.header.main_menu.menu5
              }}</a>
            </li>
            <li v-else>
              <a @click="modal = 'user'" class="user-btn"
                ><span class="icon-icon-user user-btn"></span
              ></a>
            </li>
            <li>
              <a v-on:click="modal = 'search'"
                ><span class="icon-icon-search"></span
              ></a>
            </li>
            <li>
              <a v-on:click="modal = 'cart'"
                ><v-badge
                  :content="cartItems"
                  :value="cartItems"
                  color="primary"
                  overlap
                >
                  <!-- <v-icon color="primary" medium>mdi-cart</v-icon> -->
                  <span class="icon-icon-cart"></span> </v-badge
              ></a>
            </li></ul
        ></v-toolbar-title>
      </v-app-bar>
    </div>

    <router-view
      class="main"
      :backdoor="n"
      @update-cart="updateCart"
      @update-modal="updateModal"
    ></router-view>

    <footer>
      <div class="main-footer">
        <ul>
          <!-- <li>
            <a href="http://auriscollection.com/" target="_blank">{{
              content.footer.main_footer.footer1
            }}</a>
          </li> -->
          <li>
            <a v-on:click="footer = 'faqs'">{{
              content.footer.main_footer.footer2
            }}</a>
          </li>
          <li>
            <router-link to="/contacts">{{
              content.footer.main_footer.footer3
            }}</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/" v-scroll-to="{ el: '#top', offset: -200 }"
              ><img :src="img_url + 'logo.png'"
            /></router-link>
          </li>
        </ul>
        <ul>
          <li>
            <a v-on:click="footer = 'privacy'">{{
              content.footer.main_footer.footer4
            }}</a>
          </li>
        </ul>
      </div>
      <ul class="secondary-footer">
        <li>
          <a
            target="_blank"
            href="https://www.instagram.com/auriswines/?fbclid=IwAR3PtT63kq7OPYtOr93SciE-hCpLH8mHmpTGHObhpnuJBaPYQ2G7sswi1yg"
            ><span class="icon-icon-instagram1"></span
          ></a>
        </li>
        <li>
          <a target="_blank" href="https://www.facebook.com/AurisWines/"
            ><span class="icon-icon-facebook"></span
          ></a>
        </li>
        <li>
          <a v-scroll-to="{ el: '#top', offset: -200 }"
            ><span class="icomoon icon-icon-arrow-right"></span
          ></a>
        </li>
        <li>
          <v-select :items="langs" dense v-model="pageLanguage"> </v-select>
          <span class="icomoon icon-icon-arrow-right"></span>
        </li>
      </ul>
      <p class="footer-rights">
        {{ content.footer.main_footer.footer5 }}
        {{ new Date().getFullYear() }} <br />
        {{ content.footer.main_footer.footer6 }}
      </p>
      <ul class="cards-logos">
        <li><img :src="img_url + 'icon-mastercard.png'" /></li>
        <li><img :src="img_url + 'icon-visa.png'" /></li>
        <li><img :src="img_url + 'american-express.png'" /></li>
      </ul>
    </footer>
    <v-snackbar v-model="snackbar" class="snackbar" :timeout="-1"
      ><span class="icon">16+</span>
      <span class="snackbar-text">{{
        content.footer.main_footer.footer7
      }}</span>

      <template v-slot:action="{ attrs }">
        <a v-bind="attrs" @click="snackbar = false">
          <span class="icon-icon-close"></span>
        </a>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import Registo from "@/components/Registo";
import Login from "@/components/Login";
import ForgotPassword from "@/components/Forgot-password";
import Details from "@/components/My-details";
import NewAddress from "@/components/New-address";
import Addresses from "@/components/Saved-addresses";
import Cart from "@/components/Cart";
import Cart2 from "@/components/Cart_shipping";
import Cart3 from "@/components/Cart_payment";
import Cart4 from "@/components/Cart_bank_transfer";
import Privacy from "@/components/Privacy";
import Faqs from "@/components/Faqs";
import Search from "@/components/Search";
import User from "@/components/Menu-user";
import CartCreditCard from "@/components/Cart_credit_card";
import Newsletter from "@/components/newsletter";

import api_calls from "@/modules/api_calls";

export default {
  name: "App",
  mixins: [computedMixins, methodsMixins],

  components: {
    Registo,
    Login,
    ForgotPassword,
    Details,
    NewAddress,
    Addresses,
    Cart,
    Cart2,
    Cart3,
    Cart4,
    Privacy,
    Faqs,
    Search,
    User,
    CartCreditCard,
    Newsletter,
  },

  data: () => ({
    // login: this.$store.state.loggedIn,
    drawer: null,
    modal: "",
    footer: "",
    lang: "EN",
    snackbar: true,
    newsletterInfo:"",
    // materiaisCarrinho: [],
    user: {
      name: "Catarina Ribeiro",
    },
    n: 0,
    privacyP: {},
  }),
  async created() {
    // let app = this
    let privacyEP = "privacy/"+this.version+"bo-list-all";
    let newsletterEP = "newsletter/"+this.version+"list-all";

    const res = await api_calls.call_api_get(privacyEP);
    this.privacyP = res.data[0];

    const res_news = await api_calls.call_api_get(newsletterEP);
    this.newsletterInfo = res_news.data[0];

    this.n += 1;

    if (this.$route.params.faqs == "faqs") {
      this.footer = "faqs";
    }
  },

  methods: {
    openUser: function () {
      document.getElementById("secondary-menu").classList.toggle("show");
      window.onclick = function (event) {
        if (!event.target.matches(".user-btn")) {
          var dropdowns = document.getElementsByClassName("secondary-menu");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
              openDropdown.classList.remove("show");
            }
          }
        }
      };
    },
    updateModal: async function (value) {
      this.n += 1;
      this.modal = value;
    },
    async updateCart() {
      this.n += 1;
    },
  },
  computed: {
    // privacyEP() {
    //   let temp = "privacy/"+this.version+"list-all"
    //   return temp
    // },
    // newsletterEP() {
    //   let temp = "newsletter/"+this.version+"list-all"
    //   return temp
    // },
    privacyPolicy() {
      this.n;

      return this.privacyP.content;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
    cartItems() {
      this.n;
      let len = this.materiaisCarrinho.length || 0;

      return len;
    },
    login() {
      this.n;
      let res =
        window.localStorage.getItem("loggedIn") == "true" ? true : false;

      return res;
    },
  },
  watch: {
    $route(to) {
      // this.prepareApp();
      // this.backdoor++;
      document.title = to.meta.title || "Auris";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.menu-desktop {
  padding: 10px 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: white;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  .main-menu {
    @include responsive(0, 1024px) {
      display: none;
    }
    ul {
      display: inline-block;
      vertical-align: middle;
      li {
        display: inline-block;
        text-transform: uppercase;
        font-size: 14px;
        a {
          color: $dark-grey;
          &:hover {
            color: $red;
          }
        }
        img {
          height: 100px;
        }
      }
    }
    ul:nth-child(1) {
      width: calc(50% - 50px);
      text-align: right;
      li {
        padding: 0 30px;
      }
    }
    ul:nth-child(3) {
      li {
        padding: 0 30px;
      }
    }
    ul:nth-child(4) {
      position: fixed;
      right: 0px;
      top: 45px;
      z-index: 30;
      li {
        padding-left: 10px;
        vertical-align: middle;
        a {
          span {
            font-family: "icomoon";
            font-size: 20px;
            vertical-align: middle;
          }
          .icon-icon-search:before {
            content: "\e90c";
          }
          .icon-icon-cart:before {
            content: "\e905";
          }
          .icon-icon-user:before {
            content: "\e90d";
          }
          &:hover {
            .icon-icon-cart:before {
              color: $dark-red !important;
            }
          }
        }
      }
      li:nth-child(4) {
        position: relative;
        .v-select {
          width: 53px;
          z-index: 20;
          height: 27px;
          position: relative;
          margin-right: 20px;
          .v-text-field,
          .v-input__control,
          .v-input__slot:before {
            border: none !important;
          }
          .v-input__slot:after {
            border: none !important;
          }
          .v-icon {
            display: none;
          }
        }
        margin: 2px 10px 0 2px;
        font-size: 18px;
        font-weight: 400;
        .icon-icon-arrow-right {
          margin: 0 5px;
          font-size: 10px;
          transform: rotate(270deg);
          display: inline-block;
          position: absolute;
          right: 28px;
          top: 10px;
          z-index: 10;
        }
      }
    }
  }
}
.snackbar {
  .snackbar-text {
    display: inline-block;
    width: 80%;
    vertical-align: middle;
  }
  .icon {
    border: 2px solid $dark-red;
    background-color: white;
    padding: 8px 5px;
    border-radius: 8px;
    color: black;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 16px;
  }
  .icon-icon-close {
    font-family: "icomoon";
    font-size: 10px;
    color: white;
    .icon-icon-close:before {
      content: "\e906";
    }
  }
}

.v-list-item--link:before {
  background-color: white !important;
}
.v-menu__content {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}

.secondary-menu {
  display: none;
  position: fixed;
  background-color: $red;
  z-index: 60;
  top: 44px;
  right: 127px;
  min-width: 250px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  @include responsive(0, 1024px) {
    top: 30px;
    right: 92px;
  }
  p {
    color: white;
    display: inline-block;
    font-weight: 300;
    padding: 10px 15px;
  }
  .user-icon {
    float: right;
    margin-top: 5px;
    margin-right: 10px;
    span {
      font-family: "icomoon";
      font-size: 20px;
      @include responsive(0, 1024px) {
        font-size: 20px;
      }
    }
    .icon-icon-user:before {
      content: "\e90d";
      color: white;
    }
  }
  ul {
    display: block;
    z-index: 20;
    text-align: center;
    margin-top: 10px;
    li {
      display: block;
      width: 100%;
      &:hover {
        background-color: $dark-red;
      }
      a {
        color: white;
        text-transform: uppercase;
        display: block;
        padding: 10px 0;
      }
    }
  }
}

.menu-mobile {
  display: none;
  position: relative;
  @include responsive(0, 1024px) {
    display: block;
  }
  .logo {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 100px;
    display: block;
    img {
      width: 70%;
    }
  }
  .v-list {
    padding: 50px 20px !important;
    .v-list-item__content {
      padding: 0 !important;
      .v-list-item {
        color: white !important;
        padding-left: 0;
        text-transform: uppercase;
        a {
          color: white;
          text-transform: uppercase;
          margin-left: 16px;
          line-height: 25px;
          &.active {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }
  // .menu-mobileI {
  //   padding: 50px 30px !important;
  //   li {
  //     color: white!important;
  //     margin: 20px 0;
  //     a {
  //       color: white;
  //       text-transform: uppercase;
  //     }
  //   }
  //   li:nth-child(5) {
  //     border: 1px solid white;
  //     width: 45px;
  //     height: 35px;
  //     line-height: 35px;
  //     text-align: center;
  //   }
  // }
  .menu-mobileII {
    position: fixed;
    right: 30px;
    top: 32px;
    text-align: center;
    li {
      display: inline-block;
      font-size: 12px;
      margin-left: 8px;
      text-transform: uppercase;
      @include responsive(0, 1024px) {
        margin-left: 15px;
        vertical-align: top;
      }
      @include responsive(0, 380px) {
        margin-left: 8px;
      }
      a {
        color: $dark-grey;
        &:hover {
          color: $red;
        }
        span {
          font-family: "icomoon";
          font-size: 25px;
          vertical-align: middle;
          @include responsive(0, 1024px) {
            font-size: 20px;
          }
          @include responsive(0, 380px) {
            font-size: 16px;
          }
        }
        .icon-icon-search:before {
          content: "\e90c";
        }
        .icon-icon-user:before {
          content: "\e90d";
        }
        &:hover {
          .icon-icon-cart:before {
            color: $dark-red !important;
          }
        }
      }
      a:nth-child(1) {
        line-height: 30px;
        @include responsive(0, 512px) {
          line-height: 32px;
        }
      }
      a:nth-child(2) {
        line-height: 30px;
      }
    }
    li:nth-child(3) {
      a {
        line-height: 30px;
        i {
          font-size: 30px !important;
          @include responsive(0, 1024px) {
            font-size: 26px !important;
            // margin-top: 2px;
          }
        }
      }
    }
  }
}

footer {
  background-color: white;
  position: relative;
  padding: 10px 0;
  border-top: 1px solid $light-greyII;
  .main-footer {
    @include responsive(0, 768px) {
      padding-top: 80px;
      text-align: center;
    }
    ul {
      display: inline-block;
      li {
        font-size: 15px;
        display: inline-block;
        vertical-align: middle;
        @include responsive(0, 768px) {
          font-size: 13px;
        }
        a {
          color: $dark-grey !important;
          &:hover {
            color: $red;
          }
          img {
            width: 80px;
            @include responsive(0, 768px) {
              width: 50px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    ul:nth-child(1) {
      width: calc(50% - 50px);
      text-align: right;
      @include responsive(0, 768px) {
        width: auto;
      }
      li {
        padding: 0 40px;
        @include responsive(0, 1024px) {
          padding: 0 20px;
        }
        @include responsive(0, 512px) {
          padding: 0 10px;
        }
      }
    }
    ul:nth-child(2) {
      @include responsive(0, 768px) {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    ul:nth-child(3) {
      li {
        padding: 40px;
        @include responsive(0, 1024px) {
          padding: 0 20px;
        }
        @include responsive(0, 512px) {
          padding: 0 10px;
        }
      }
    }
  }
  .secondary-footer {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    text-align: left;
    @include responsive(0, 768px) {
      text-align: center;
    }
    li {
      margin: 3px 0;
      a {
        span {
          font-family: "icomoon";
          font-size: 20px !important;
          color: $dark-grey;
        }
      }
    }
    li:nth-child(3) {
      a {
        transform: rotate(90deg);
        display: block;
        width: 20px;
        height: 30px;
        padding-left: 4px;
        @include responsive(0, 768px) {
          padding-left: 0px;
        }
      }
    }
    li:nth-child(4) {
      position: relative;
      @include responsive(0, 768px) {
        margin-left: 10px;
        .v-text-field {
          margin-top: 0px;
        }
      }
      .v-select {
        width: 53px;
        z-index: 20;
        height: 27px;
        position: relative;
        margin-right: 20px;
        .v-text-field,
        .v-input__control,
        .v-input__slot:before {
          border: none !important;
        }
        .v-input__slot:after {
          border: none !important;
        }
        .v-icon {
          display: none;
        }
      }
      margin: 0px;
      font-size: 18px;
      font-weight: 400;
      .icon-icon-arrow-right {
        margin: 0 10px;
        font-size: 10px;
        transform: rotate(270deg);
        display: inline-block;
        position: absolute;
        right: 28px;
        top: 7px;
        z-index: 10;
        @include responsive(0, 768px) {
          top: 6px;
        }
      }
    }
    @include responsive(0, 768px) {
      position: relative;
      top: 0;
      right: 0;
      transform: translateY(0%);
      margin-top: 30px;
      li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
      }
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    line-height: 20px;
    &.footer-rights {
      font-size: 12px;
    }
  }
  .cards-logos{
    text-align: center;
    li{
      display: inline-block;
      height: 25px;
      margin: 0 5px;
      img{
        height: 100%;
      }
    }
  }
}

.v-app-bar.v-app-bar--fixed {
  z-index: 50 !important;
}

.v-navigation-drawer--fixed {
  z-index: 55 !important;
}

//rewrite

.event {
  .description {
    p {
      text-align: left !important;
      margin-bottom: 20px;
      color: $dark-grey;
    }

    h1 {
      font-family: $font1;
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 30px;
      color: $dark-grey;
    }
  }
}

.v-badge__badge {
  font-size: 12px !important;
}

.v-dialog {
  box-shadow: none;
}

.icon-icon-cart:before {
  content: "\e905";
}

.v-menu__content {
  z-index: 500 !important;
}

.product_description {
  p {
    text-align: left;
    max-width: 100%;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
  }
}

.description_event {
  font-size: 17px;
  line-height: 30px;
  font-family: $font2;
  font-weight: 400;
  color: $dark-grey;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 17px;
    line-height: 30px;
    font-family: $font2;
    font-weight: 400;
    color: $dark-grey;
    span {
      font-size: 17px;
      line-height: 30px;
      font-family: $font2;
      font-weight: 400;
      color: $dark-grey;
    }
  }
}

.login-word {
  margin-top: 5px;
}

.v-ripple__container {
  display: none !important;
}

.termos {
  .v-expansion-panel-header {
    p {
      color: $red;
      font-weight: 600;
      text-align: left !important;
      margin-bottom: 0;
      a {
        text-decoration: underline;
      }
    }
  }
  .v-expansion-panel-content {
    display: block;
    p {
      padding-top: 10px;
      padding-left: 24px;
      text-align: left;
      a {
        color: $dark-grey;
        text-decoration: underline;
      }
    }
  }
}

.termos {
  padding: 5vw;
  @include responsive(0, 768px) {
    padding: 40px;
  }
  @include responsive(0, 512px) {
    padding: 40px 20px;
  }
  h2 {
    color: $dark-grey !important;
    text-align: left;
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  p {
    text-align: left;
    margin-bottom: 0;
  }
}

.newsletter {
  .v-messages {
    display: none !important;
    div {
      display: none !important;
    }
  }
  .v-text-field__slot {
    label {
      font-size: 12px;
    }
  }
}
</style>
